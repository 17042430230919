<template>
  <div class="loading-indicator" v-if="loading">
    <div class="loading-indicator__content">
      <div class="bb-spinner">
        <div class="bb-spiner__content">
          <div class="bb-spinner__dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="bb-spinner__title">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      message: "Cargando la pantalla de pago...", // Default message
    };
  },
};
</script>
